.custom-react-table {

  .table-container-scroll-x {
    width: 100%;
    overflow-x: auto;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .select-none {
    user-select: none;
  }

  &.clickable {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  .pagiation-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;

    .table-pagination {
      margin: 20px;

      a.active.item {
        background-color: lightgray;
      }
    }

    .page-size-options {
      margin: 20px;

      .divider.text {
        width: 80px;
      }
    }
  }

  .no-data-found-text {
    text-align: center;
    font-weight: bold;
    padding: 50px;
    border: 1px solid rgba(34, 36, 38, .15);
    border-top: none;
  }

  .empty-table {
    table {
      margin-bottom: 0;
      border-bottom: none;
    }

  }

  .ui.table tbody tr {
    &.row-grouped {
      font-weight: bolder;
    }

    &.row-highlighted {
      background-color: skyblue !important;
    }
  }

  .ui.selectable.table tbody tr {
    &:hover {
      background-color: lightblue !important;
    }
  }

  .ui.table thead.sticky tr:first-child>th {
    position: sticky !important;
    top: 0;
    z-index: 2;
  }
}