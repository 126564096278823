.Login {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    width: 20%;
    min-width: 250px;
    max-width: 450px;
}

.LoginPane {
    display: grid;
    align-content: center;
}

.LoginPane .LoginSegment .lds-dual-ring {
    margin: 0;
}

.LoginPane .LoginPaneButtons {
    width: 100%;
}

.LoginPane .AdditionalOptions {
    margin-top: 20px;

    div {
        text-decoration: underline;
        color: darkgray;
        cursor: pointer;
        margin-bottom: 20px;
    }

    a {
        text-decoration: underline;
        color: darkgray;
        cursor: pointer;
        margin-bottom: 20px;
    }
}

.LoginPane .LogoImg {
    display: inline-block !important;
}
