.NewAppointmentControlPane {
    margin-top: 30px;
}

.NewAppointmentControlPane .DeleteAppointmentButton {
    float: left;
}

.AppointmentScheduler .AppointmentPatientResultName {
    display: inline-block;
}

.AppointmentScheduler .AppointmentPatientResultDob {
    float: right;
}

.AppointmentScheduler .results.transition {
    max-height: 300px;
    overflow-y: auto;
}
