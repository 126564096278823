.MainBoard {
    margin: 0px !important;
    border: none !important;
    border-radius: 0 !important;
    height: calc(100% - 50px) !important;
    position: absolute;
    top: 50px;
    left: 0;
}

.MainContent {
    height: 100%;
    width: calc(100% - 150px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.MainContent .ContentMainPane {
    padding-left: 20px;
    padding-right: 20px;
}

.MainContent.FullWidth {
    height: 100%;
    width: 100%;
}

.SideBar {
    background: #303030 !important;
    font-size: medium !important;
}
.SideBar.Mobile {
    position: absolute;
    top: 50px;
    left: 0;
    font-size: small !important;
}

.SideBar a {
    height: 80px !important;
    text-align: center !important;
    line-height: 60px !important;
}

.SideBar.Mobile a {
    height: 50px !important;
    text-align: center !important;
    line-height: 30px !important;
}

.SideBar a.active {
    font-weight: bolder !important;
}

.HiddenLink {
    display: none;
}

.MainContentDiv {
    padding: 5px;
    height: 100%;
    width: 100%;
}