.ReportingPane {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.ReportingPane .InlineDateInput {
    width: 250px;
    padding: 5px;
    display: inline-block;
}