.TreatmentNzra {
    height: 100%;
    width: 100%;
}

.TreatmentMethodSpan {
    display: inline;
    margin-left: 10px;
    vertical-align: super;
}

.TreatmentMethodCheckBox {
    display: inline !important;
}

.TreatmentNzra .TreatmentMainCol {
    min-width: 210px;
}

.TreatmentNzra .TreatmentAdditionalCol {
    min-width: 180px;
}

.TreatmentNzra .Slider {
    padding-bottom: 20px;
}

.TreatmentNzra .ui.form .fields .field.flex-form-field {
    display: flex;
    margin-bottom: 8px;
}