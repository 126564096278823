.SearchPatient {
    padding: 20px;
    height: 100%;
}

.SearchPatient .SearchInput {
    max-width: 600px;
    min-width: 100px;
    width: 50%;
    margin-bottom: 20px;
    height: 36px;
}

.SearchPatient {
    height: 100%;
    /*overflow: auto;
    -webkit-overflow-scrolling: touch;*/
}
.SearchPatient .SearchCriteriaDiv {
    margin-bottom: 20px;
}

.SearchPatient .Criterion {
    margin: 10px;
    min-width: 50px;
}

.SearchPatient .NewPatientDiv {
    text-align: right;
}

.SearchPatient .CustomReactTable  {
    /*max-height: 400px;*/
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

/*@media (max-height: 700px) {
    .SearchPatient .CustomReactTable  {
        max-height: 350px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (min-height: 900px) {
    .SearchPatient .CustomReactTable  {
        max-height: 550px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (min-height: 1000px) {
    .SearchPatient .CustomReactTable  {
        max-height: 650px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (min-height: 1100px) {
    .SearchPatient .CustomReactTable  {
        max-height: 750px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (min-height: 1200px) {
    .SearchPatient .CustomReactTable  {
        max-height: 850px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}*/