.TreatmentNzasa {
    width: 100%;
    height: 100%;
}
.TreatmentNzasa .TreatmentMainCol {
    min-width: 210px;
}

.TreatmentNzasa .TreatmentAdditionalCol {
    min-width: 180px;
}

.TreatmentNzasa .Slider {
    padding-bottom: 20px;
}

.TreatmentNzasa .ui.form .fields .field.flex-form-field {
    display: flex;
    margin-bottom: 8px;
}

.TreatmentNzasa .tcm-diagnosis-groups .checkbox {
    margin-bottom: 8px;
}