.PasswordReset {
    width: 20%;
    min-width: 200px;
    max-width: 400px;
}

.PasswordResetControls {
    padding-top: 20px;
}

.PasswordReset .password-reset-msg {
    text-align: left;
}