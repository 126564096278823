.ActivitySummary {

}
.ActivitySummary .ReportParamPane {
    border: 1px solid lightgray;
    border-radius: 5px;
}

.ActivitySummary .ModalContent {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.ActivitySummary .FilterInput {
    width: 80px;
    padding: 5px;
}