.InjuryDetail {
    padding: 20px;
    height: 100%;
    /*overflow: auto;
    -webkit-overflow-scrolling: touch;*/

    .RemoveReadCodeIcon {
        cursor: pointer !important;
    }
    
    .ReadCodeDropdown {
        display: inline-block;
    }

    .ReadCodeAddButton.ui.button {
        float: right;
        margin: 0;
    }

    .TreatmentTable input[type='checkbox'] {
        width: 20px;
        height: 20px;
    }

    .readcode-search-input .results.transition {
        width: 100%;
        max-height: 300px;
        overflow-y: auto;
    }

    .readcode-search-result.readcode-search-result {
        &.read-code-result,
        > span {
            font-weight: 900;
        }
        &.loading,
        &.too-many-results {
            font-style: italic;
            color: darkgray;
        }
        &.no-result {
            color: darkgray;
        }
    }
}