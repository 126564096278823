.LoginPage {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    overflow: auto;
    padding-top: 10vh;
    padding-bottom: 2vh;
    position: relative;
}

.LoginPage .Banner {
    margin-bottom: 20px;
}

.LoginPage .Banner img {
    display: inline-block !important;
}
