.App {
  left:0;
  top:0;
  width: 100%;
  height: 100%;
}

.App .notification-container {
  top: 50px !important;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.ui.selection.dropdown,
.ui.form input,
.ui.basic.label,
.ui.form textarea,
.ui.input>input {
  border-color: lightslategray !important;
  transform: none;
  -webkit-transform: translateZ(0px);
}

.ui.selection.dropdown:focus,
.ui.form input:focus,
.ui.form textarea:focus,
.ui.input>input:focus {
  border-color: deepskyblue !important;
  transform: none;
  -webkit-transform: translateZ(0px);
}

.ui.icon.input>i {
  z-index: 1;
}

.ui .field .ui.checkbox:not(.radio):not(.toggle) label:before {
  border: 1px solid lightslategray !important;
  width: 20px !important;
  height: 20px !important;
}

.ui .field .ui.checkbox:not(.radio):not(.toggle) label:after {
  top: 1px !important;
  left: 2px !important;
  font-size: 16px !important;
  color: white !important;
}

.ui.checkbox:not(.radio):not(.toggle) input:checked~.box:before,
.ui.checkbox:not(.radio):not(.toggle) input:checked~label:before {
  border: 1px solid lightslategray !important;
  background-color: #2185d0 !important;
}

.ui.checkbox:not(.radio):not(.toggle) input:checked~label:after {
  color: white !important;
}

.ui.table thead tr th {
  background-color: #ebebeb !important;
}

.App .rc-slider {
  height: 20px;
}

.App .rc-slider-dot {
  width: 15px;
  height: 15px;
  bottom: -7px;
}

.App .rc-slider-handle {
  width: 20px;
  height: 20px;
  opacity: 1;
  bottom: 2px;
}

.App .css-hidden {
  display: none;
}

.App .no-wrap {
  white-space: nowrap;
}

div:focus {
  /* fix an issue where react-table header shows a blue border on focus */
  outline: none;
}
