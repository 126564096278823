.TemplatePane {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.TemplatePane .TableContent {
    max-height: 500px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.TemplatePane .CreateNewGrid {
    align-self: center !important;
}

.TemplatePane .TemplateEditingControlPane {
    margin-top: 10px;
}

.TemplatePane .TemplateDiv,
.TemplatePane .TemplateEditingDiv {
    margin-top: 20px;
    margin-bottom: 20px;
}

.TemplatePane .DeleteIcon {
    cursor: pointer !important;
}

.TemplatePane .TableHeader {
    padding-top: 20px !important;
}

.TemplatePane .NewCategoryBtn {
    float: left;
}

.TemplateEditingControlPane .HelpText {
    float: left;
    text-decoration: underline;
    margin-left: 20px;
    cursor: pointer;
}

.TemplateEditingControlPane .HelpIcon {
    float: left;
    margin: 10px;
}