.AppointmentViewer {
    overflow-wrap: break-word;

    .DeleteAppointmentButton {
        float: left;
    }

    .AppointmentCommentHeader {
        font-weight: bolder;
    }
}