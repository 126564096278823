.CustomLoader {
  position: relative !important;
}

.lds-dual-ring {
  margin-right: 30px;
  vertical-align: middle;
  display: inline-block;
  width: 30px;
  height: 30px;
}

.lds-dual-ring.fullScreen {
  position: absolute;
  top: 50%;
  left: 50%;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid black;
  border-color: black transparent black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}