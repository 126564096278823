.ControlPane {
    width: 100%;
    min-height: 50px;
    margin: 0;
    padding: 0;
    display: block;
    text-align: right;
}

.ControlPane button {
    margin: 5px 2px !important;
}

.ControlPane .flex-right {
    flex: auto;
    text-align: right;
    align-self: center;
}