.BigCalendarDiv {
    height: calc(100% - 100px);
}

.BigCalendarDiv .rbc-event-label {
    display: none;
}

.BigCalendarDiv.loading .rbc-time-view,
.BigCalendarDiv.loading .rbc-month-view,
.BigCalendarDiv.loading .rbc-agenda-view {
    opacity: 0.5;
}

.AppointmentPane {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.AppointmentPane .ShowHoursCount .rbc-allday-cell {
    display: none;
}

.AppointmentPane .ShowHoursCount .rbc-time-header-cell-single-day {
    display: block;
}

.HourTips span {
    margin-right: 20px;
}