.ModalControlPane {
    margin-top: 30px;
}

.SettingPane {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 50px;
}

.SettingsForm .TreatmentAlertField {
    display: inline-block;
    padding: 0 5px;
}

.SettingsForm .TreatmentAlertField.TreatmentAlertInput .ui.input>input{
    width: 80px !important;
    padding: 5px !important;
}

.SettingsForm .StackField {
    margin-bottom: 0 !important;
}

.SettingsForm .StackField p,
.SettingsForm .StackField div {
    margin-bottom: 0 !important;
}

.SettingsForm .NotificationList .item {
    padding-bottom: 15px !important;
}

.SettingsForm .student-info-header {
    margin-top: 20px !important;
}