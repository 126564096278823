.TabHeader {
    display: inline-flex;
    border: 1px solid gray;
    border-bottom: none;
    margin-right: 3px;
    height: 40px;
    min-width: 60px;
    vertical-align: bottom;
    text-align: center;
    user-select: none;
    background-color: gray;
    color: white;
}

.TabHeader.active {
    font-weight: bold;
    background-color: #fff;
    border-bottom: none;
    height: 41px;
}

.TabHeader.active .TabHeaderTitle {
    color: black;
}

.TabHeader.active .TabHeaderCloseIcon {
    font-weight: bold;
    background-color: #fff;
    color: black;
}

.TabHeader .TabHeaderTitle {
    text-align: left;
    padding-left: 10px;
    line-height: 40px;
    display: inline-block;
    height: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.TabHeader:not(.active):hover {
    background: darkgray;
}


.TabHeader .TabHeaderCloseIcon {
    text-align: right;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    background-color: transparent;
    align-self: center;
}

.TabHeader .TabHeaderCloseIconInvisible {
    margin-left: 10px;
    margin-right: 10px;
    background: transparent;
}

.TabHeader .TabHeaderCloseIcon:hover {
    background: lightgray;
    font-weight: bolder;
}

.TabHeader.Mobile {
    border: none;
    margin-right: 0;
    height: 20px;
    min-width: 0;
    background: white;
    color: black;
}

.TabHeader.Mobile .TabHeaderTitle {
    line-height: 20px;
    background: white;
    color: black;
}
