.TabContentSection {
    width: 100%;
    height: calc(100% - 51px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.TabContentSection.Mobile {
    width: 100%;
    overflow: auto;
}