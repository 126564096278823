.TopBar {
    width: 100%;
    height: 50px;
    background: #303030;
    color:white;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
}

.TopBar .MenuToggler {
    display: inline;
    padding-left: 20px;
    cursor: pointer;
}

.TopBar .Brand {
    display: inline-block;
    height: 50px;
}

.TopBar .Brand .BrandName {
    line-height: 50px;
    padding-left: 20px;
    font-size: x-large;
    text-align: center;
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}

.TopBar .Content {
    display: inline-block;
    text-align: center;
    margin-left: 20%;
}
.TopBar p {
    display: inline-block;
}

.TopBar .UserDetail {
    float: right;
    padding-right: 20px;
    line-height: 50px;
}

.TopBar .UserDetail p {
    display: inline-block;
    margin-right: 20px;
}

.TopBar .TopLogoImg {
    display: inline !important;
    padding-left: 30px;
}

.TopBar .UserProfile {
    cursor: pointer;
    line-height: 45px;
}

.UserProfilePopup p {
    display: inline-block;
}

.UserProfilePopup .PopupAlt {
    display: none !important;
}

.TopBar .Faq {
    margin-left: 10px;
    cursor: pointer;
}
@media (max-width : 370px) {
    .TopBar .Brand .BrandName {
        font-size: large;
    }
}

@media (max-width: 700px) {
    .TopBar .UserProfile .UserAccount {
        display: none;
    }
    .UserProfilePopup .PopupAlt {
        display: inline-block !important;
    }
    .TopBar .UserDetail .SignOut {
        display: none !important;
    }
    .TopBar .UserDetail .Faq {
        display: none;
    }
}

@media (max-width: 1150px) {
    .TopBar .Content {
        display: none;
    }
}
