.PatientDetail {
    padding: 20px;
    height: 100%;
    /*overflow: auto;
    -webkit-overflow-scrolling: touch;*/

    .InjuryHistoryDiv {
        padding-top: 30px;
    }

    .additional-info-title {
        margin-top: 20px !important;
    }
}