.InvoiceAccModal .CertinfoLink {
    font-weight: bolder;
}

.InvoiceAccModal .HelpIcon {
    cursor: pointer;
}

.InvoiceAccModal .Highlighted {
    color: red;
}